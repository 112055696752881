@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Belleza-Regular;
  src: local('Belleza-Regular'), url(/src/fonts/Belleza-Regular.ttf) format("truetype");
}

@font-face {
  font-family: NunitoSans-Regular;
  src: local('NunitoSans-Regular'), url(/src/fonts/NunitoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Montserrat-Regular;
  src: local('Montserrat-Regular'), url(/src/fonts/Montserrat-Regular.ttf) format("truetype");
}

@layer components {
  .row {
    @apply flex flex-row
  }

  .col {
    @apply flex flex-col
  }

  .button-blue {
    @apply px-5 py-2 text-xs outline-none items-start bg-gradient-to-b from-skyBlue to-waterBlue text-white font-bold rounded-lg shadow-lg
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
